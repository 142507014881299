import * as React from "react";
import fetch from "isomorphic-fetch";

// Shopify Storefront API GraphQL endpoint
const STORE_API_URL = `https://${process.env.GATSBY_SHOPIFY_STORE_URL}/api/2024-04/graphql.json`;

// Create a function to send GraphQL queries
const fetchGraphQL = async (query, variables = {}) => {
  const res = await fetch(STORE_API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Shopify-Storefront-Access-Token":
        process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
    },
    body: JSON.stringify({ query, variables }),
  });
  const json = await res.json();
  if (json.errors) {
    console.error(json.errors);
    throw new Error("GraphQL request failed");
  }
  return json.data;
};

// Define the GraphQL queries and mutations
const CREATE_CHECKOUT = `
  mutation createCheckout {
    checkoutCreate(input: {}) {
      checkout {
        id
        lineItems(first: 10) {
          edges {
            node {
              id
              title
              quantity
              variant {
                id
                image {
                  url
                  src
                }
                priceV2 {
                  amount
                  currencyCode
                }
              }
            }
          }
        }
        subtotalPriceV2 {
          amount
          currencyCode
        }
        totalPriceV2 {
          amount
          currencyCode
        }
        totalTaxV2 {
          amount
          currencyCode
        }
        webUrl
      }
    }
  }
`;

const ADD_LINE_ITEM = `
  mutation addLineItem($checkoutId: ID!, $variantId: ID!, $quantity: Int!) {
    checkoutLineItemsAdd(checkoutId: $checkoutId, lineItems: [{variantId: $variantId, quantity: $quantity}]) {
      checkout {
        id
        lineItems(first: 10) {
          edges {
            node {
              id
              title
              quantity
              variant {
                id
                image {
                  url
                  src
                }
                priceV2 {
                  amount
                  currencyCode
                }
              }
            }
          }
        }
        subtotalPriceV2 {
          amount
          currencyCode
        }
        totalPriceV2 {
          amount
          currencyCode
        }
        totalTaxV2 {
          amount
          currencyCode
        }
        webUrl
      }
    }
  }
`;

const REMOVE_LINE_ITEM = `
  mutation removeLineItem($checkoutId: ID!, $lineItemId: ID!) {
    checkoutLineItemsRemove(checkoutId: $checkoutId, lineItemIds: [$lineItemId]) {
      checkout {
        id
        lineItems(first: 10) {
          edges {
            node {
              id
              title
              quantity
              variant {
                id
                image {
                  url
                  src
                }
                priceV2 {
                  amount
                  currencyCode
                }
              }
            }
          }
        }
        subtotalPriceV2 {
          amount
          currencyCode
        }
        totalPriceV2 {
          amount
          currencyCode
        }
        totalTaxV2 {
          amount
          currencyCode
        }
        webUrl
      }
    }
  }
`;

const UPDATE_LINE_ITEM = `
  mutation updateLineItem($checkoutId: ID!, $lineItemId: ID!, $quantity: Int!) {
    checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: [{id: $lineItemId, quantity: $quantity}]) {
      checkout {
        id
        lineItems(first: 10) {
          edges {
            node {
              id
              title
              quantity
              variant {
                id
                image {
                  url
                  src
                }
                priceV2 {
                  amount
                  currencyCode
                }
              }
            }
          }
        }
        subtotalPriceV2 {
          amount
          currencyCode
        }
        totalPriceV2 {
          amount
          currencyCode
        }
        totalTaxV2 {
          amount
          currencyCode
        }
        webUrl
      }
    }
  }
`;

const defaultValues = {
  cart: [],
  isOpen: false,
  loading: false,
  onOpen: () => {},
  onClose: () => {},
  addVariantToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
  checkout: {
    lineItems: [],
  },
};

export const StoreContext = React.createContext(defaultValues);

const isBrowser = typeof window !== `undefined`;
const localStorageKey = `shopify_checkout_id`;

export const StoreProvider = ({ children }) => {
  const [checkout, setCheckout] = React.useState(defaultValues.checkout);
  const [loading, setLoading] = React.useState(false);
  const [didJustAddToCart, setDidJustAddToCart] = React.useState(false);

  const setCheckoutItem = (checkout) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id);
    }

    setCheckout(checkout);
  };

  React.useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null;

      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const checkoutData = await fetchGraphQL(
            `
            query fetchCheckout($id: ID!) {
              node(id: $id) {
                ... on Checkout {
                  id
                  lineItems(first: 10) {
                    edges {
                      node {
                        id
                        title
                        quantity
                        variant {
                          id
                          image {
                            url
                            src
                          }
                          priceV2 {
                            amount
                            currencyCode
                          }
                        }
                      }
                    }
                  }
                  subtotalPriceV2 {
                    amount
                    currencyCode
                  }
                  totalPriceV2 {
                    amount
                    currencyCode
                  }
                  totalTaxV2 {
                    amount
                    currencyCode
                  }
                  webUrl
                }
              }
            }
          `,
            { id: existingCheckoutID }
          );

          const existingCheckout = checkoutData.node;
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout);
            return;
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, null);
        }
      }

      // Create a new checkout if no checkout exists
      const newCheckout = await fetchGraphQL(CREATE_CHECKOUT);
      setCheckoutItem(newCheckout.checkoutCreate.checkout);
    };

    initializeCheckout();
  }, []);

  const addVariantToCart = (variantId, quantity) => {
    setLoading(true);

    const checkoutID = checkout.id;
    return fetchGraphQL(ADD_LINE_ITEM, {
      checkoutId: checkoutID,
      variantId,
      quantity: parseInt(quantity, 10),
    }).then((res) => {
      setCheckout(res.checkoutLineItemsAdd.checkout);
      setLoading(false);
      setDidJustAddToCart(true);
      setTimeout(() => setDidJustAddToCart(false), 3000);
    });
  };

  const removeLineItem = (checkoutID, lineItemID) => {
    setLoading(true);

    return fetchGraphQL(REMOVE_LINE_ITEM, {
      checkoutId: checkoutID,
      lineItemId: lineItemID,
    }).then((res) => {
      setCheckout(res.checkoutLineItemsRemove.checkout);
      setLoading(false);
    });
  };

  const updateLineItem = (checkoutID, lineItemID, quantity) => {
    setLoading(true);

    return fetchGraphQL(UPDATE_LINE_ITEM, {
      checkoutId: checkoutID,
      lineItemId: lineItemID,
      quantity: parseInt(quantity, 10),
    }).then((res) => {
      setCheckout(res.checkoutLineItemsUpdate.checkout);
      setLoading(false);
    });
  };

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        checkout,
        loading,
        didJustAddToCart,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
